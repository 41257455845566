<template>

  <div class="view__accounts view-full">
    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <MembersFilter
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      class="mb-0 view-full"
    >
      <b-row class="py-1">
        <b-col cols="12">

          <div class="d-flex justify-content-between align-items-center">
            <div>
              <b-button
                variant="primary"
                class="btn-icon"
                :to="{name: 'members-create'}"
              >
                <feather-icon icon="UserPlusIcon" />
                {{ $t('Add New Member') }}
              </b-button>
            </div>
            <div>
              <VueGoodTableSwitchCompactMode />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="table__view__full">
        <b-col cols="12">
          <div class="table__full">
            <VueGoodTableRemote
              ref="tableDataMember"
              :total-records="totalRecords"
              :rows="rows"
              :columns="columns"
              :is-line-number="true"
              :is-loading="$wait.is('table-loading-data') || $wait.is('loading-on-action-update-max-credit-limit')"
              :select-enabled="true"
              @onLoadData="onLoadData"
              @selectionChanged="selectionChanged"
            >
              <div slot="my-selected-row-actions">
                <Button
                  :text="$t('Update Max Credit Limit')"
                  variant="primary"
                  :loading="$wait.is('loading-on-action-update-max-credit-limit')"
                  @click="onUpdateMaxCreditLimit"
                />
              </div>

              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusColorBoolean(props.row.active)">
                    {{ statusActiveBoolean(props.row.active) }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'creditLimit'">
                  <span>
                    {{ props.formattedRow[props.column.field] | formatNumberMoney }}
                  </span>
                </span>
                <div
                  v-else-if="props.column.field === 'username'"
                  class="table__row"
                >
                  <span
                    class="table__row link--text-latest"
                  >
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </div>
                <span v-else-if="props.column.field === 'last_login'">
                  {{ DatetimeFormat(props.row.last_login) }}
                </span>
                <div
                  v-else-if="props.column.field === 'editSetting'"
                  class="d-flex justify-content-center"
                >
                  <b-button
                    variant="primary"
                    class="btn-icon rounded-circle"
                    size="sm"
                    :to="{
                      name: 'members-edit',
                      params: {
                        id: props.row._id
                      }
                    }"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </div>
                <div
                  v-else-if="props.column.field === 'ActionCredit'"
                  class="d-flex justify-content-center"
                >
                  <div class="pr-1">
                    <b-button
                      variant="success"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click.prevent="onIncrementCredit(props.row)"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>

                  </div>
                  <div>
                    <b-button
                      variant="danger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click.prevent="onDecrementCredit(props.row)"
                    >
                      <feather-icon icon="MinusIcon" />
                    </b-button>
                  </div>
                </div>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </VueGoodTableRemote>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <ModalIncrementDecrementCreditComponent
      :show.sync="modalCredit.show"
      :type="modalCredit.type"
      :username="modalCredit.username"
      :current-credit="modalCredit.currentCredit"
      @onConfirm="onConfirmModalCredit"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Button from '@/components/Button.vue'
import vSelect from 'vue-select'
import store from '@/store'
import MembersFilter from '@/views/members/MembersFilter'
import { VueGoodTable } from 'vue-good-table-custom'
import ModalIncrementDecrementCreditComponent from '@/components/ModalIncrementDecrementCreditComponent'
import VueGoodTableRemote from '@/components/VueGoodTableRemote'

import {
  statusColorBoolean,
  statusActiveBoolean,
} from '@/utils/statusBoolean.util'
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'

import { DatetimeFormat } from '@/utils/date-format'

export default {
  components: {
    VueGoodTableRemote,
    VueGoodTableSwitchCompactMode,
    MembersFilter,
    VueGoodTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    Button,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    ModalIncrementDecrementCreditComponent,
    vSelect,
  },
  data() {
    return {
      columns: [
        {
          label: this.$t('Username'),
          field: 'username',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ...',
            formatValue(value) {
              return String(value).trim()
            },
          },
          width: '130px',
        },
        {
          label: this.$t('Contact'),
          field: 'contact',
          sortable: false,
        },
        {
          label: this.$t('Level'),
          field: 'type',
          sortable: false,
        },
        {
          label: this.$t('Currency'),
          field: 'currency',
          sortable: false,
          width: '80px',
        },
        {
          label: this.$t('Status'),
          field: 'status',
          sortable: false,
        },
        {
          label: this.$t('Credit'),
          field: 'creditLimit',
          type: 'decimal',
        },
        {
          label: this.$t('MaxCreditLimit'),
          field: 'maxCreditLimit',
          type: 'decimal',
        },
        {
          label: '',
          field: 'ActionCredit',
          sortable: false,
        },
        {
          label: this.$t('Setting'),
          field: 'editSetting',
          sortable: false,
          width: '60px',
        },
        {
          label: this.$t('Last Time Login'),
          field: 'last_login',
          width: '200px',
        },
      ],
      rows: [],
      totalRecords: 0,

      memberId: null,

      modalCredit: {
        show: false,
        type: "INCREMENT",
        username: "",
        id: "",
        currentCredit: 0,
      },

      selectedRows: [],
    }
  },
  computed: {
    breadcrumbs() {
      return store.getters['appBreadCrumb/getBreadcrumbs']
    },
    userInfo() {
      return store.getters['auth/userInfo']
    },
    user() {
      return store.getters['auth/user']
    },
  },
  watch: {
    $route() {
      const { memberId } = this.$route.params
      this.setDownlineParam(memberId)

      if (!memberId) {
        store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')
      } else {
        this.onLoadData();
      }
    },
  },
  mounted() {
    const { memberId } = this.$route.params
    this.setDownlineParam(memberId)
    this.onLoadData()
  },
  methods: {
    statusColorBoolean,
    statusActiveBoolean,
    DatetimeFormat,

    setDownlineParam(memberId) {
      this.memberId = memberId
    },

    onClickAddNewAccount() {
      this.$router.push({ name: "members-create" })
    },

    async onLoadData() {
      const params = this.$refs.tableDataMember.getParams()

      this.$wait.start('table-loading-data')
      try {
        const mainPath = '/member/accounts'
        const path = this.memberId ? `${mainPath}/${this.memberId}` : mainPath
        const { data } = await this.$http.get(path, {
          params,
        })
        const isSuccess = data?.success ?? false
        if (isSuccess) {
          this.rows = data?.data?.docs ?? []
          this.totalRecords = data?.data?.totalDocs ?? 0

          this.$router.replace({ query: params }).catch(error => {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          })
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('table-loading-data')
      }
    },

    onIncrementCredit(row) {
      this.modalCredit.currentCredit = row.creditLimit
      this.modalCredit.username = row.username
      this.modalCredit.id = row._id
      this.modalCredit.type = "INCREMENT"
      this.modalCredit.show = true
    },
    onDecrementCredit(row) {
      this.modalCredit.currentCredit = row.creditLimit
      this.modalCredit.username = row.username
      this.modalCredit.id = row._id
      this.modalCredit.type = "DECREMENT"
      this.modalCredit.show = true
    },
    async  onConfirmModalCredit(total) {
      if (!total || Number.isNaN(total) || Number(total) <= 0) {
        this.$swal({
          icon: 'error',
          title: this.$t('INPUT_INVALID_CREDIT'),
        })
        return
      }

      this.$wait.start('loading-on-action-credit')
      try {
        const { data } = await this.$http.patch(`/member/accounts/credit/${this.modalCredit.id}`, {
          total: parseFloat(total),
          type: this.modalCredit.type,
        })
        if (!data.success) {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
          return
        }

        const currentIndexByMember = this.rows.findIndex(list => String(list._id) === String(this.modalCredit.id))
        if (currentIndexByMember !== -1) {
          const allRows = [...this.rows]
          allRows[currentIndexByMember] = { ...allRows[currentIndexByMember], creditLimit: data.data.member.creditLimit }
          this.rows = allRows
        }
        this.$swal({
          icon: 'success',
          title: this.modalCredit.type === 'INCREMENT' ? this.$t('IncrementCreditSuccess') : this.$t('DecrementCreditSuccess'),
        })

        const agent = data?.data?.agent ?? null
        if (agent) {
          // update agent
          store.dispatch('auth/setUser', agent)

          // update agentInfo
          store.commit('auth/userInfo', { ...this.userInfo, creditLimit: agent.creditLimit })
        }

        this.modalCredit.show = false
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message),
        })
        this.modalCredit.show = false
      } finally {
        this.$wait.end('loading-on-action-credit')
      }
    },

    selectionChanged(selectedRows) {
      this.selectedRows = selectedRows
    },
    async onUpdateMaxCreditLimit() {
      const { selectedRows } = this
      if (!selectedRows || !Array.isArray(selectedRows) || Array.from(selectedRows).length === 0) {
        this.$swal({
          icon: 'error',
          title: this.$t('Please Select Rows'),
        })
        return
      }

      if (selectedRows.length > 100) {
        this.$swal({
          icon: 'error',
          title: this.$t('Max Update 100 Rows'),
        })
        return
      }

      const ids = selectedRows.map(list => list.id)

      this.$wait.start('loading-on-action-update-max-credit-limit')
      try {
        const { data } = await this.$http.patch(`/member/accounts/max-credit-limit`, {
          ids,
        })
        if (!data.success) {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
          return
        }

        const agent = data?.data?.agent ?? null
        if (agent && agent?.username === this.user.username) {
          // update agent
          store.dispatch('auth/setUser', agent)

          // update agentInfo
          store.commit('auth/userInfo', { ...this.userInfo, creditLimit: agent.creditLimit })
        }

        const updatedTotal = data?.data?.updatedTotal ?? 0
        if (updatedTotal > 0) {
          await this.onLoadData()
        }

        this.$swal({
          icon: 'success',
          title: `Success Updated : ${updatedTotal} Accounts`,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message),
        })
      } finally {
        this.$wait.end('loading-on-action-update-max-credit-limit')
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.view__accounts {
  margin-top: -50px;
}
.view-full {
  position: relative;
  height: 100%;
  width: 100%;
}
.table__view__full {
  display: block;
  position: relative;
  width: auto;
  max-height: calc(100% - 60px);
  overflow-y: auto;
}

</style>
